.skill-item {
  /* border: 1px solid gray; */
  width: 30%;
  margin: 10px;
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
}

.hidden{
  visibility: hidden;
}
