@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffffff;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


@-webkit-keyframes fade-in {
  0% {
    -webkit-transform: scale(.7,.7);
            transform: scale(.7,.7);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fade-in {
  0% {
    -webkit-transform: scale(.7,.7);
            transform: scale(.7,.7);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideFromLeft {
  0% {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
    opacity: 0;
  }
 
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  0% {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
    opacity: 0;
  }
 
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-element {

}

body {
  font-family: 'Roboto', sans-serif;
  color: #333333;    
  background-color: rgba(255, 255, 255, 0.7);
  min-width:280px;       
  width: auto !important; 
  width:280px;
}

p {
  font-family: 'Josefin Sans', sans-serif;
  color: rgba(255, 255, 255, 0.7);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway';
  color: black;
  
}

h2{
  font-size: 50px;
  margin-bottom: 30px;
}

.hidden{
  visibility: hidden;
}

.slide{
  -webkit-animation: fade-in .25s;
          animation: fade-in .25s;
}

section {
  padding-top: 75px;
  padding-bottom: 75px;
  min-height: 100vh;
}

#about, #projects {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
}

#skills, #contact {
  background-color: rgb(240, 240, 240);
}

.landing-btn {
  /* background-color: darkgray; */
  background-color: transparent;
  border: 3px solid whitesmoke;
  color: whitesmoke;
  font-weight: bold;
  height: 50px;
  /* width: 140px;  */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:70%;
  cursor: pointer;
}

.landing-btn:hover {
  background-color: orange;
}

.landing-btn:active {
  opacity: .5;
}

.fade-in-section {
  opacity: 0; 
  -webkit-transform: translateY(20vh); 
          transform: translateY(20vh);
  visibility: hidden;
  -webkit-transition: opacity 0.6S ease-out, -webkit-transform 1.2s ease-out;
  transition: opacity 0.6S ease-out, -webkit-transform 1.2s ease-out;
  transition: opacity 0.6S ease-out, transform 1.2s ease-out;
  transition: opacity 0.6S ease-out, transform 1.2s ease-out, -webkit-transform 1.2s ease-out;
  will-change: opacity, visiblity;
}

.fade-in-section.is-visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.parallax {
  /* The image used */
  background-image: url(/static/media/stars.40e7dec3.jpeg);
  /* Full height */
  height: 50px;
  
  /* Create the par allax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Home Section */
#home{
  z-index: 2;
  /* background-color: rgb(255, 213, 25); */
  background-color: #1b242f;
  color: #C33149;
  height: 100vh;
  margin-bottom: 75px;
  overflow-y: hidden;
}

.home-hilite {
  font-weight: white;
  color: white;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
}

.home-box {
  position: absolute;
  top: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway';
}

.home-1 {

}

.home-2 {
  margin-bottom: 15px;
}

.home-button {
  font-size: 5px !important;
  cursor: pointer;
}

/* About Section */
.about-content {
  padding-left: 10vw;
  padding-right: 10vw;
}

.about-profile {
  width:100%;
  margin-bottom: 5px;
  margin-right: 50px;
}

.about-job {
  font-size: 20px;
  margin-bottom: 0px;
}

.about-name {
  font-size: 40px;
  margin-bottom: 15px;
}

.about-desc {
  text-align: justify;
  margin-bottom: 15px;
}

.about-list {
  text-align: left;
  padding-left: 50px;
}

/* Project Section */

.proj-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* flex-direction: column;
  align-items: center; */
  max-width: 900px;
}

.proj-box {
  border: 1px solid gray;
  border-radius: 5px;
  width: 45%;
  min-width: 300px;
  max-width: 450px;
  /* max-width: 500px; */
  /* margin-left: 10vw;
  margin-right: 10vw; */
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.proj-title {
  color: black;
  font-weight: bold;
}

.proj-text {
  color: black;
}

.proj-hr {
  border-top: 1px solid orange;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Contact Section */ 
.bullet-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.contact-bullet {
  height:25px;
  margin-right: 5px;
}

.contact-content {
  margin-left: 15vw;
  margin-right: 15vw; 
}

.contact-links {
  text-align: left;
  margin-bottom: 20px;
}

.contact-main {
  border: 1px solid gray;
  border-radius: 5px;
  max-width: 80vw;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.contact-button-row {
  display: flex;
  justify-content: center;
}

.contact-button {
  margin-top: 20px;
  padding: 10px;
  width: 200px;
  font-weight: bold;
  /* border-radius: 5px; */
  border: 3px solid orange;
  color: whitesmoke;
  background-color: orange;
  cursor: pointer; 
  text-align: center;
}

.contact-button:hover {
  opacity: .6;
}

.contact-button:active {
  opacity: .3;
} 

.page-button {
  font-weight: bold;
}

.page-button:hover {
  background-color: purple;
}

.hero-text {
  font-family: ;
  color: ;
}

.main-skill-row {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.extra-skill-row {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.skill {
  border: 1px solid black;
}

.section {
    
}

.circular {
    border-radius: 50%;
    height: 200px;
    width: 200px;
}

.italic { 
  font-style: italic;
}

.bold {
  font-style: bold;
}

.sidebar {

}

.sidebar-link {
  font-family: ;
  color: ;
}

.sidebar-link:hover {

}

.sidebar-link:active {

}

.flex {
  display: flex;
}

.flex-vert {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

#home{
  width: 100%;
}

.flex-left {
  display: flex;
  justify-content: left;
}

.flex-right {
  display: flex;
  justify-content: right;
}

.flex-center {
  display: flex;
  align-content: center;
}

/* trash */


.gray-out {
  opacity: 1.0;
}

.gray-out:hover {
  opacity: .5;
}
.left-main {
  width: 30%;
}

.center-main {
  width: 40%;
}

.right-main {
  width: 30%;
}

.border-black {
  border: 2px solid black;
}

.items-row {
  display: flex;
}

.landing-navbar {
  position: absolute;
  bottom: -10vh;
  height: 10vh;
  background-color: gray;
  width: 100%;
}

@media screen and (min-width: 600px){
  .about-content {
    display: flex;
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 800px;
  }
}

@media screen and (min-width: 800px){
  .contact-box {
    display: flex;
    justify-content: center;
  }

  .contact-content {
    display: flex;
    justify-content: space-evenly;
    max-width: 900px;
  }

  .contact-links {
    text-align: left;
    margin-bottom: 20px;
    width: 40%;
    font-size: 18px;
    /* border: 1px solid blue; */
  }
  
  .contact-main {
    border: 2px solid gray;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    width: 50%;
    padding: 40px;
  }
}
@-webkit-keyframes popDown {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

@keyframes popDown {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

@-webkit-keyframes popUp {
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

@keyframes popUp {
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

.topbar{
  left:0;
  height: 50px;
  min-height: 50px;
  background-color: #fff;
  width:100%;
  text-align:center;
  font-size:22px;
  font-family: 'Raleway';
  box-shadow: 0px 4px 4px rgba(0,0,0,0.2);
  -webkit-transition: top .3s;
  transition: top .3s;
  z-index: 10;
}

.non-stick{
  position: absolute;
  bottom: -50px;
}

.stick-top{
  position: fixed;
  top:0vh;
  -webkit-animation: popDown .75s;
          animation: popDown .75s;
}

/* Navbar Styling */
.navbar {
  font-size: 15px;
  height:100%;
  /* background-image: linear-gradient(260deg, #2376ae 0%, #c16ecf   100%); */
  background-image: linear-gradient(260deg, #1b242f 0%, #1b242f 100%);
  border: 1px solid rgba(0, 0, 0, 0.2);
  bottom: 0px;
  z-index: 5;
  border-bottom: 3px solid orange;
}
.link-wrap {
  list-style-type: none;
  display: none;
}

.link-wrap li {
  text-align: center;
  margin: 15px auto;
}

.nav-links,
.logo {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}                   
.logo {
  display: none;
  font-size: 22px;
  margin-top: 10px;
  margin-left: 20px;
}

.hb-show{
  display: block;
  height: 110px;
  -webkit-transition: height .25s;
  transition: height .25s;
}

.hb-hide{
  display: block;
  height: 0px;
  -webkit-transition: height .25s;
  transition: height .25s;
}

.hb-img {
  height: 70%;
}

.hb-img:hover {
  opacity: .50;
}

.hb-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  height: 100%;
  align-items: center;
}

.hb-menu {
  display: block;
  background-color: gray;
  -webkit-animation: popDown;
          animation: popDown;
  z-index: -1;
  overflow: hidden;
}  

.hb-menu-close {
  display: block;
  background-color: gray;
  -webkit-animation: popDown;
          animation: popDown;
  z-index: -1;
  height: 0px;
  -webkit-transition: 2s;
  transition: 2s;
}  

@media screen and (min-width: 600px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    position: static;
    bottom: 0;
    right: 0;
  }
  .link-wrap {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-start;
  }
  .link-wrap li {
    margin: 0;
  }
  .nav-links {
    margin-left: 40px;
    cursor: pointer;
  }
  .nav-links, .logo{
    display: block;
  }

  .logo {
    margin-top: 0;
   }
  /* .navbar-toggle {
    display: none;
  } */
  .logo:hover,
  .nav-links:hover {
    color: rgba(255, 255, 255, 1);
  }

  .nav-links:active {
    opacity: .5;
  }

  .hb-button {
    display: none;
  }
  .hb-menu {
    display: none;
  }  
}
.skill-item {
  /* border: 1px solid gray; */
  width: 30%;
  margin: 10px;
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
}

.hidden{
  visibility: hidden;
}

.mynavbar{
  position: fixed;
  left:0;
  top:0;
  background-color: #fff;
  width:100%;
  text-align:center;
  font-size:22px;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.2);
  -webkit-transition:top .3s;
  transition:top .3s;
}

.hide{
  
}

.nav-links:hover {
  color: rgba(255, 255, 255, 1);
}
