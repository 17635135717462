.mynavbar{
  position: fixed;
  left:0;
  top:0;
  background-color: #fff;
  width:100%;
  text-align:center;
  font-size:22px;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.2);
  transition:top .3s;
}

.hide{
  
}

.nav-links:hover {
  color: rgba(255, 255, 255, 1);
}